<!--服务费管理-->
<template>
  <page-container title="违约情况" :breadcrumb="breadcrumb">
    <!--    查询栏-->
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="simple-query">
          <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form">

            <a-form-model-item label="公司/项目" prop="userdepid">
              <a-cascader  :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }" v-model="queryParams.selectDeptList" :options="deptOptions?deptOptions.filter(item => item.deptype===1):[]" :load-data="loadChildrenDept" placeholder="请选择公司/项目" :change-on-select="true" :allow-clear="false"></a-cascader>
            </a-form-model-item>
            <a-form-model-item label="服务内容" prop="service_content">
              <a-input v-model.trim="queryParams.service_content" placeholder="请输入服务内容"></a-input>
            </a-form-model-item>
            <div class="query-btns">
              <a-button @click="resetQueryParams">重置</a-button>
              <a-button type="primary" class="after-btn" @click="getTableData(true)">查询</a-button>
              <a-button type="link" @click="showAdvanced=!showAdvanced">展开
                <a-icon :type="showAdvanced?'up':'down'"></a-icon>
              </a-button>
            </div>
          </a-form-model>
        </div>
        <div v-show="showAdvanced" class="advanced-query">
          <a-form-model ref="advancedForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="收费类型" prop="fees_type">
              <a-select v-model="queryParams.fees_type">
                <a-select-option value="">全部</a-select-option>
                <a-select-option :value="0">物业管理费</a-select-option>
                <a-select-option :value="1">停车费</a-select-option>
                <a-select-option :value="2">特约服务费</a-select-option>
                <a-select-option :value="3">保证金</a-select-option>
                <a-select-option :value="4">其他</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="收费标准" prop="fees_standard">
              <a-input v-model="queryParams.fees_standard" placeholder="请输入收费标准"></a-input>
            </a-form-model-item>
            <a-form-model-item label="收费形式" prop="fees_form">
              <a-select v-model="queryParams.fees_form">
                <a-select-option value="">全部</a-select-option>
                <a-select-option :value="0">包干制</a-select-option>
                <a-select-option :value="1">酬金制</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="服务标准" prop="service_standard">
              <a-input v-model="queryParams.service_standard" placeholder="请输入服务标准"></a-input>
            </a-form-model-item>
          </a-form-model>
        </div>
      </div>
    </div>
    <!--    展示列表栏-->
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="table-header">
          <h3 class="table-title">服务及收费列表</h3>
          <div class="table-btns">
            <a-button type="primary" @click="showModal('add')">
              <a-icon type="plus"></a-icon>
              新建
            </a-button>
            <a-icon type="redo" class="after-btn table-refresh" @click="getTableData(true)"></a-icon>
          </div>
        </div>
        <a-table :columns="tableColumns" :data-source="tableData" row-key="id" :loading="tableLoading"
                 :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')"
                 style="margin-top: 20px;" :pagination="false"  :customRow="handleClickRow">
          <span slot="fees_form" slot-scope="text">
             {{ text === 0 ? '包干制' : '酬金制' }}
          </span>
          <span slot="service_standard" slot-scope="text" class="service_standard_content_style">
            {{text}}
          </span>
          <span slot="fees_type" slot-scope="text">
             {{ text === 0 ? '物业管理费' : text === 1 ?'停车费':text === 2 ?'特约服务费':text === 3 ? '保证金':'其他'}}
          </span>
          <span slot="service_standard_annex" slot-scope="value, record" class="service_standard_annex-view">
              <a-tag v-if="record.service_standard_annex==='1'" class="text" @click.stop="downloadAnnexClick(record.annex)">
                {{ record.annex.split("/").pop()}}
              </a-tag>
            <a-tag v-else class="text red">
                无附件
              </a-tag>
          </span>
          <span slot="operation" slot-scope="value, record">
            <a-dropdown>
              <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                <a-icon type="menu"/>
              </a>
              <a-menu slot="overlay" @click="operationClick">
                <a-menu-item :key="'detail-'+record.id">详情</a-menu-item>
                <a-menu-item :key="'edit-'+record.id">修改</a-menu-item>
                <a-menu-item :key="'delete-'+record.id">删除</a-menu-item>
              </a-menu>
            </a-dropdown>
          </span>
        </a-table>
        <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer
                      @change="pageChange" @showSizeChange="onShowSizeChange"
                      :show-total="(total, range) => `共${total}条`"
                      style="margin-top: 20px; text-align: right;">
          <template slot="buildOptionText" slot-scope="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </div>
    <!--    新建、修改违约页面-->
    <a-modal v-model="modalVisible" :title="modalTitle" :width="900" :dialog-style="{top: '25px'}"
             :body-style="modalBodyStyle">
      <template slot="footer">
        <a-button @click="modalCancel">{{modalType==='detail'?'关闭':'取消'}}</a-button>
        <a-button v-if="modalType=='add'||modalType=='edit'"  type="primary" @click="modalConfirm">提交</a-button>
      </template>
      <a-form-model ref="modalForm" :model="modalForm" :rules="modalRules" layout="inline" class="form-in-twocolumns"
                    :label-col="{span:9}" :wrapper-col="{span:14}">
        <a-form-model-item label="公司/项目" prop="userdepid">
          <a-cascader :disabled="modalType==='detail'"  :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }" v-model="modalForm.selectDeptList" :options="deptOptions?deptOptions.filter(item => item.deptype===1):[]" :load-data="loadChildrenDept" placeholder="请选择公司/项目" :change-on-select="true" :allow-clear="false"></a-cascader>
        </a-form-model-item>
        <a-form-model-item label="服务内容" prop="service_content">
          <a-textarea :disabled="modalType==='detail'" v-model.trim="modalForm.service_content" placeholder="请输入服务内容"/>
        </a-form-model-item>
        <a-form-model-item label="收费类型" prop="fees_type">
          <a-select :disabled="modalType==='detail'" v-model="modalForm.fees_type">

            <a-select-option :value="0">物业管理费</a-select-option>
            <a-select-option :value="1">停车费</a-select-option>
            <a-select-option :value="2">特约服务费</a-select-option>
            <a-select-option :value="3">保证金</a-select-option>
            <a-select-option :value="4">其他</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="收费标准" prop="fees_standard">
          <a-textarea :disabled="modalType==='detail'" v-model="modalForm.fees_standard" placeholder="请输入收费标准,按照物业服务合同填写，如同一小区存在多个标准的应一一枚举"></a-textarea>
        </a-form-model-item>
        <a-form-model-item label="收费形式" prop="fees_form">
          <a-select :disabled="modalType==='detail'" v-model="modalForm.fees_form">

            <a-select-option :value="0">包干制</a-select-option>
            <a-select-option :value="1">酬金制</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="服务标准" prop="service_standard">
          <a-textarea :disabled="modalType==='detail'" v-model="modalForm.service_standard" placeholder="请输入服务标准,按物业服务合同约定"></a-textarea>
        </a-form-model-item>
        <a-form-model-item label="服务标准附件" prop="service_standard_annex">
          <a-upload name="fileList" :disabled="modalType==='detail'" :headers="uploadHeaders" :data="uploadAnnexData" action="/upload" :file-list="annexList" @change="annexUploaded"
                    accept=".doc,.docx,.pdf" :beforeUpload="beforeUpload">
            <a-button :disabled="modalType==='detail'"> <a-icon type="upload" /> 点击上传文件 </a-button>
          </a-upload>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </page-container>
</template>
<script>
import {
  addPerformanceServicesFees,
  getServiceFeesListByCondition,
  deleteOneServiceFees,
  editServiceFees
} from 'A/performance'
import {
  getItemFromArrayByKey,
} from 'U'
import moment from 'moment'
import {mapState} from "vuex";
import deptselect from '@/mixins/deptselect'
import {mapGetters} from "vuex";
import {userdep} from "V/dataAnalysis/performance/minins/userdep";

export default {
  name: 'servicesFees',
  mixins: [deptselect,userdep],
  data() {
    return {
      showAdvanced: false,
      moment,
      annexList:[],
      modalBodyStyle: {
        maxHeight: '560px',
        overflowY: 'auto',
      },
      breadcrumb: [
        {
          name: '业务管理',
          path: ''
        },
        {
          name: '履约管理',
          path: ''
        },
        {
          name: '履约数据',
          path: ''
        },
        {
          name: '服务及收费',
          path: ''
        }
      ],
      queryParams: {
        service_content: '',
        fees_type: '',  //0-物业管理费,1-停车费,2-特约服务费,3-保证金,4-其他
        fees_standard: '',
        fees_form: '',  //0-包干制,1-酬金制
        service_standard: '',
        userdepid:'',
        selectDeptList:[]
      },
      //服务内容 收费类型	收费标准	收费形式	服务标准
      tableColumns: [
        {
          title: '公司/项目',
          dataIndex: 'userdepname',
          key: 'userdepname'
        },
        {
          title: '服务内容',
          dataIndex: 'service_content',
          key: 'service_content'
        },
        {
          title: '收费类型',
          dataIndex: 'fees_type',
          key: 'fees_type',
          scopedSlots: { customRender: 'fees_type' }
        },
        {
          title: '收费标准',
          dataIndex: 'fees_standard',
          key: 'fees_standard'
        },
        {
          title: '收费形式',
          dataIndex: 'fees_form',
          key: 'fees_form',
          scopedSlots: { customRender: 'fees_form' }
        },
        {
          title: '服务标准',
          dataIndex: 'service_standard',
          key: 'service_standard',
          scopedSlots: { customRender: 'service_standard' }
        },
        {
          title: '服务标准附件',
          dataIndex: 'service_standard_annex',
          key: 'service_standard_annex',
          scopedSlots: { customRender: 'service_standard_annex' }
        },
        {
          title: '操作',
          key: 'operation',
          scopedSlots: {customRender: 'operation'}
        },
      ],
      tableData: [],
      tableLoading: false,
      currentPage: 1,
      pagination: {
        total: 0,
        pageSize: 10,
      },
      modalVisible: false,
      modalType: '',
      modalForm: {
        id:0,
        service_content: '',
        fees_type: '',//0-物业管理费,1-停车费,2-特约服务费,3-保证金,4-其他
        fees_standard: '',
        fees_form: '',//0-包干制,1-酬金制
        service_standard: '',
        annex:'',//服务标准的附件
        userdepid:"",
        selectDeptList:[],
      },
      modalRules: {
        service_content: [{required: true, message: '请输入服务内容'}],
        fees_type: [{required: true, message: '请选择收费类型'}],
        fees_standard: [{required: true, message: '请输入收费标准,按照物业服务合同填写，如同一小区存在多个标准的应一一枚举'}],
        fees_form: [{required: true, message: '请选择收费形式'}],
        service_standard: [{required: true, message: '请输入服务标准，按物业服务合同约定'}],
        userdepid: [{required: true, message: '请选择公司/项目'}]
      },
      selectedServicesFees:null
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    ...mapGetters(['uploadHeaders', 'uploadAnnexData']),
    modalTitle() {
      let title = '';
      if (this.modalType == 'add') {
        title = '新增';
      } else if (this.modalType == 'detail') {
        title = '详情';
      } else if (this.modalType == 'edit') {
        title = '修改';
      } else {
        title = '';
      }
      return title;
    },
  },
  watch: {
    'modalForm.selectDeptList'(val) {
      if(val && val.length) {
        this.modalForm.userdepid = val.join('|')
        this.$refs.modalForm.clearValidate('userdepid');
        // this.initMaintenancegroupidOptions();
      }else {
        this.modalForm.userdepid = ''
      }
    },
    'queryParams.selectDeptList'(val) {
      if(val && val.length) {
        this.queryParams.userdepid = val[val.length-1];
        // this.queryParams.userdepid = val.join('|')
        // this.$refs.queryForm.clearValidate('userdepid');
        // this.initMaintenancegroupidOptions();
      }else {
        this.queryParams.userdepid = ''
      }
    },
  },

  created() {
    this.queryParams.userdepid=this.userInfo.useraccount==='admin'?null:this.userInfo.userdepid
    this.init();
  },
  methods: {
    init() {
      this.getTableData();
    },
    resetQueryParams() {
      this.queryParams.selectDeptList=[]
      this.$refs.queryForm.resetFields();
      this.$refs.advancedForm.resetFields();
    },
    getTableData(firstPage) {
      if (firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;
      // let userdepid = this.queryParams.userdepid;
      // if(userdepid.indexOf('|') > -1) {
      //   userdepid = userdepid.substr(userdepid.lastIndexOf('|')+1);
      // }
      let params = {
        ...this.queryParams,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize,
        //userdep_id:this.userInfo.useraccount==='admin'?null:this.userInfo.userdepid,
        //userdepid:this.userInfo.useraccount==='admin'?null:this.userInfo.userdepid,
      };
      getServiceFeesListByCondition(params).then(res => {
        this.tableLoading = false;
        console.log(res.item)
        if (res && res.returncode === '0') {
          this.tableData = res.item?res.item.map(item => ({
            ...item,
            service_standard_annex: item.hasOwnProperty('annex')?(item.annex===''?'0':'1'):'0',
            annex:item.hasOwnProperty('annex') ? item.annex : '',
          })):[]
          this.pagination.total = res.count;
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    pageChange(page, size) {
      this.getTableData();
    },
    onShowSizeChange(page, size) {
      this.pagination.pageSize = size;
      this.getTableData(true);
    },
    showModal(type, value, record) {
      this.modalType = type;
      if (type == 'add') {
        this.modalVisible = true;
        this.modalForm.id=0
        this.modalForm.service_content=''
        this.modalForm.service_standard= ''
        this.modalForm.fees_form= ''
        this.modalForm.fees_standard= ''
        this.modalForm.fees_type= ''
        this.modalForm.annex= ''
        this.modalForm.userdepid= ''
        this.modalForm.selectDeptList= []
        this.annexList= []
      } else {
        this.initUserDeptid(record.userdep_id)
        this.modalVisible = true;
        this.modalForm.id=record.id
        this.modalForm.service_content=record.service_content
        this.modalForm.service_standard= record.service_standard
        this.modalForm.fees_form= record.fees_form
        this.modalForm.fees_standard= record.fees_standard
        this.modalForm.fees_type= record.fees_type
        this.modalForm.annex=record.annex
        this.modalForm.userdepid = record.userdep_id
        const file_name = record.annex.split("/").pop();
        this.annexList = [{
          uid: 'filenameuid',
          name: file_name,
          status: 'done',
          url: record.annex,
        }];
      }
    },
    deleteConfirm(value, record) {
      this.$confirm('确定要删除该数据？',{
        type:'warning',
        centered:true,
      }).then(()=>{
        this.delete(record.id);
      }).catch(()=>{
      });
    },


    delete(serviceFees_id) {
      if (serviceFees_id) {
        let params = {
          serviceFees_id
        };
        deleteOneServiceFees(params).then(res => {
          if (res && res.returncode == '0') {
            this.$message.success('操作成功');
            this.getTableData();
          } else {
            this.$message.error(res.errormsg || '操作失败');
          }
        })
      }
    },
    modalConfirm() {
      if (this.modalType == 'add' || this.modalType == 'edit') {
        this.$refs.modalForm.validate(valid => {
          if (valid) {
            this.addOrEdit();
          } else {
            return false;
          }
        })
      } else {
        this.modalVisible = false;
      }
    },
    //添加或者编辑违约单
    addOrEdit() {
      this.showLoading();
      console.log(this.modalForm)
      let userdepid = this.modalForm.userdepid;
      if(userdepid.indexOf('|') > -1) {
        userdepid = userdepid.substr(userdepid.lastIndexOf('|')+1);
      }
      let params = {
        service_content: this.modalForm.service_content,
        fees_type: this.modalForm.fees_type,
        fees_standard: this.modalForm.fees_standard,
        fees_form: this.modalForm.fees_form,
        service_standard: this.modalForm.service_standard,
        annex:this.modalForm.annex,
        userdep_id: userdepid
      };
      if (this.modalType == 'add') {
        console.log(params)
        addPerformanceServicesFees(params).then(
            this.addOrEditResult);
      } else if (this.modalType == 'edit') {
        params.id = this.modalForm.id;
        console.log(params)
        editServiceFees(params).then(this.addOrEditResult)
      }
    },
    addOrEditResult(res) {
      this.hideLoading();
      if (res && res.returncode == '0') {
        this.$message.success('操作成功');
        this.modalVisible = false;
        this.getTableData();
      } else {
        this.$message.error(res.errormsg || '操作失败');
      }
    },
    modalCancel() {
      this.modalVisible = false;
    },
    resetModal() {
      this.$refs.modalForm.resetFields();
    },
    operationClick({key}) {
      let arr = key.split('-');
      let type = arr[0];
      let id = arr[1];
      console.log(arr)
      this.selectedServicesFees = getItemFromArrayByKey(this.tableData, 'id', id);
      console.log(this.selectedServicesFees)
      if ('edit' == type) {
        this.showModal('edit', id, this.selectedServicesFees)
      } else if ('detail' == type) {
        this.showModal('detail', id, this.selectedServicesFees)
      } else if ('delete' == type) {
        this.deleteConfirm(id, this.selectedServicesFees)
      }
    },
    handleClickRow(record,index){
      console.log(record)
      return {
        on: {
          click: () => {
            this.operationClick({key:"detail-"+String(record.id)});
          }
        }
      }
    },
    //上传服务标准的附件
    annexUploaded(info) {
      let fileList = [...info.fileList];
      fileList = fileList.slice(-1);
      //console.log(fileList)
      fileList = fileList.map(file => {
        if (file.response) {
          file.url = file.response.urlPath;
          this.modalForm.annex = file.response.urlPath;
        }
        return file;
      });
      this.annexList = fileList;
    },
    downloadAnnexClick(path){
      if(path!==''){
        const pageUrl = window.location.origin;
        const name = path.split("/").pop();
        const download_path=pageUrl+"/"+path;
        let a = document.createElement('a');
        a.href = download_path;
        a.download = name;
        a.click();
      }else{
        this.$message.error("文件为空！");
      }
    },
    beforeUpload(file){
      return new Promise((resolve, reject) => {
        const isJpgOrPng =
            file.type === "application/pdf" ||
            file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
            file.type === "application/msword";
        if (!isJpgOrPng) {
          this.$message.error("上传文件格式只能是doc/docx/pdf");
          return reject(false);
        }
        const isLimit = file.size / 1024 / 1024 < 5;
        if (!isLimit) {
          this.$message.error("上传文件大小不能超过 5MB!");
          return reject(false);
        }
        return resolve(true);
      });
    },
  }
}
</script>
<style lang="scss" scoped>
.menu-modal-container {
  // display: flex;
  // position: relative;
  height: 100%;
  overflow-y: auto;
  margin-right: -24px;
}
.service_standard_annex-view {
  display: flex;
  align-items: center;
  .text {
    display: inline-block;
    margin-left: 7px;
    //border: 1px solid black;
    color: #57c921;
    &.red {
      color: #e70c0c;
    }
  }
}
.service_standard_content_style{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  width: 250px;
}

</style>